import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import Logo from '../../components/Logo';

// MUI imports
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';

type FormValues = {
    login: string;
    password: string;
};

const Login = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);
    const [passwordFieldFocused, setPasswordFieldFocused] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        navigate('/sessions');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Logo size="large" />
            <FormLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '70%', maxWidth: '25em', height: '20%', maxHeight: '9em' }}>
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('login', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="trainingCenter"
                    label={i18n.t('Login.placeholder.login')}
                    type="text"
                />
                <SignEvalTextField
                    icon={
                        passwordFieldFocused ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ marginRight: '1px', color: theme.palette.text.primary }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <LockOutlinedIcon />
                            </InputAdornment>
                        ) : (
                            <LockOutlinedIcon />
                        )
                    }
                    iconPosition="end"
                    registerProps={{
                        ...register('password', {
                            required: true,
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="password"
                    label={i18n.t('Login.placeholder.password')}
                    type={showPassword ? 'text' : 'password'}
                    onFocus={() => setPasswordFieldFocused(true)}
                    onBlur={() => setPasswordFieldFocused(false)}
                />
                {(errors.login || errors.password) && <Typography sx={{ color: 'red', fontSize: '0.75em', margin: '0' }}>{i18n.t('Login.error.login-password')}</Typography>}
            </FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '70%', height: '20%', maxHeight: '9em' }}>
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('Login.link.logIn')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ fontSize: '0.8em', color: theme.palette.text.primary }}>{i18n.t('Login.withoutAccount')}</Typography>
                    <Link id="signUpButton" to="/signup-info">
                        <Typography sx={{ fontSize: '0.8em', textDecoration: 'underline', color: theme.palette.text.primary }}>{i18n.t('Login.link.signUp')}</Typography>
                    </Link>
                </Box>
                <Link id="passwordForgotten" to="/password-forgotten">
                    <Typography sx={{ fontSize: '0.8em', textDecoration: 'underline', color: theme.palette.text.primary }}>{i18n.t('Login.link.passwordForgotten')}</Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default Login;
