import React from 'react';
import { Link } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '../hooks/useTheme';

interface DrawerLinkProps {
    to: string;
    icon: SvgIconComponent;
    text: string;
}

const DrawerLink: React.FC<DrawerLinkProps> = ({ to, icon: Icon, text }) => {
    const theme = useTheme();

    return (
        <Link to={to} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: '0.5em 0', color: 'black', textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary }}>
                <Icon sx={{ margin: '0 0.75em', color: theme.palette.text.primary }} />
                {text}
            </Box>
        </Link>
    );
};

export default DrawerLink;
