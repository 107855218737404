import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import Logo from '../../components/Logo';

// MUI imports
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

type FormValues = {
    email: string;
};

const PasswordForgotten: React.FC = () => {
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        // Logic to handle form submission
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary
            }}
        >
            <Logo size="large" />
            <Typography
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '70%',
                    maxWidth: '30em',
                    fontSize: '0.9em',
                    margin: '1em 0',
                    textAlign: 'center',
                    color: theme.palette.text.primary
                }}
            >
                {i18n.t('PasswordForgotten.description')}
            </Typography>
            <FormLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '70%', maxWidth: '25em', height: '10%', maxHeight: '5em' }}>
                <SignEvalTextField
                    icon={<MailOutlineIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        })
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email') + '*'}
                    type="email"
                />
                {errors.email && <Typography sx={{ color: 'red', fontSize: '0.75em', margin: '0' }}>{i18n.t('Login.error.emailPattern')}</Typography>}
            </FormLabel>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '70%',
                    height: '20%',
                    maxHeight: '9em',
                    color: theme.palette.text.primary
                }}
            >
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('PasswordForgotten.button')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
                    <Typography sx={{ fontSize: '0.8em', color: theme.palette.text.primary }}>{i18n.t('Login.withoutAccount')}</Typography>
                    <Link to="/signup-info" style={{ color: theme.palette.text.primary }}>
                        <Typography sx={{ fontSize: '0.8em' }}>{i18n.t('Login.link.signUp')}</Typography>
                    </Link>
                </Box>
                <Link to="/">
                    <Typography sx={{ fontSize: '0.8em', textDecoration: 'underline', color: theme.palette.text.primary }}>{i18n.t('Login.link.logIn')}</Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default PasswordForgotten;
