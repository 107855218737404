import React from 'react';
import { CountrySelector, usePhoneInput, CountryIso2 } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useTheme } from '../hooks/useTheme';

// Chakra imports
import { Button, ChakraProvider, Input, InputGroup, InputRightElement } from '@chakra-ui/react';

// MUI imports
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Box from '@mui/material/Box';

interface ChakraPhoneProps {
    value: string;
    onChange: (phone: string) => void;
}

export const ChakraPhone: React.FC<ChakraPhoneProps> = ({ value, onChange }) => {
    const theme = useTheme();
    const phoneInput = usePhoneInput({
        defaultCountry: 'fr',
        value,
        onChange: (data) => {
            onChange(data.phone);
        }
    });

    const selectedCountryIso2 = phoneInput.country.iso2 as CountryIso2;

    const borderColor = theme.palette.mode === 'light' ? '#C9C9C9' : 'default-border-color';

    return (
        <ChakraProvider>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <CountrySelector
                    selectedCountry={selectedCountryIso2}
                    onSelect={(country) => phoneInput.setCountry(country.iso2)}
                    renderButtonWrapper={({ children, rootProps }) => (
                        <Button
                            {...rootProps}
                            variant="outline"
                            px="4px"
                            mr="15px"
                            sx={{
                                width: '87.5%',
                                height: '3em',
                                borderRadius: '10px',
                                borderColor: borderColor,
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.default
                            }}
                        >
                            {children}
                        </Button>
                    )}
                />
                <InputGroup>
                    <Input
                        placeholder="Phone number"
                        type="tel"
                        color="primary"
                        value={phoneInput.phone}
                        onChange={(e) => phoneInput.handlePhoneValueChange(e)}
                        ref={phoneInput.inputRef}
                        sx={{
                            borderRadius: '10px',
                            minHeight: '3em',
                            width: '100%',
                            borderColor: borderColor,
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.default
                        }}
                    />
                    <InputRightElement style={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, marginTop: '4px', marginRight: '6px' }}>
                        <PhoneOutlinedIcon />
                    </InputRightElement>
                </InputGroup>
            </Box>
        </ChakraProvider>
    );
};
