import { useState } from 'react';
import QRCodeLink from 'react-qr-code';
import i18n from '../i18n/i18n';

// MUI imports
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QrCode2Icon from '@mui/icons-material/QrCode2';

// Helpers
import { useTheme } from '../hooks/useTheme';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

interface QRCodeProps {
    link: string;
    token: string;
}

const QRCode = ({ link, token }: QRCodeProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const fgColorQRCode = theme.palette.mode === 'light' ? '#000000' : '#FFFFFF';

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <QrCode2Icon sx={{ fontSize: '3.3em', color: theme.palette.text.primary }} />
            </IconButton>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '15px 15px 0 0',
                        paddingBottom: '1em',
                        width: '100%',
                        height: '90%',
                        '@media (max-height:700px)': {
                            height: '86.5%'
                        }
                    }
                }}
            >
                <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end', margin: '0.5em' }} aria-label="Close QR code drawer">
                    <CloseIcon sx={{ color: theme.palette.text.custom, fontSize: '1.35em' }} />
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            textAlign: 'center',
                            margin: '1em 0 2.5em 0',
                            fontSize: '1.3em',
                            '@media (max-height:700px)': {
                                fontSize: '1.15em',
                                margin: '0 0 1.5em 0'
                            }
                        }}
                    >
                        {i18n.t('QRCode.CodeText')}
                    </Typography>
                    <QRCodeLink value={link} bgColor="transparent" fgColor={fgColorQRCode} size={200} />
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            textAlign: 'center',
                            margin: '2.5em 0 2.5em 0',
                            fontSize: '1.3em',
                            '@media (max-height:700px)': {
                                fontSize: '1.15em',
                                margin: '1.5em 0 1em 0'
                            }
                        }}
                    >
                        {i18n.t('QRCode.TokenText')}
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.custom,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            letterSpacing: '0.15em',
                            marginBottom: 'auto',
                            fontSize: '2.75em'
                        }}
                    >
                        {token}
                    </Typography>
                </Box>
            </Drawer>
        </>
    );
};

export default QRCode;
