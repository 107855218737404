import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { useRegistrationContext } from '../../hooks/useRegistrationContext';
import { ChakraProvider } from '@chakra-ui/react';
import { RegistrationData } from '../../types/registrationTypes';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import { ChakraPhone } from '../../components/ChakraPhone';
import Logo from '../../components/Logo';

// MUI imports
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

type FormValues = RegistrationData;

const SignUpInfo: React.FC = () => {
    const theme = useTheme();

    const navigate = useNavigate();
    const [phoneValue, setPhoneValue] = React.useState<string>('');
    const [phoneError, setPhoneError] = React.useState<string>('');

    const { setRegistrationData } = useRegistrationContext();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange'
    });

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        data.password = '';
        data.phoneNumber = phoneValue;
        if (phoneValue === '') {
            setPhoneError(i18n.t('Login.PhoneRequired'));
            return;
        }

        setRegistrationData(data);
        navigate('/signup-passwords');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Logo size="large" />
            <FormLabel
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '70%',
                    maxWidth: '25em',
                    height: '55%',
                    minHeight: '21em',
                    maxHeight: '25em'
                }}
            >
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('label', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="trainingCenter"
                    label={i18n.t('Login.placeholder.trainingCenter') + '*'}
                    error={!!errors.label}
                    type="text"
                />
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('siret', {
                            required: false,
                            pattern: {
                                value: /^[0-9]{14}$/,
                                message: i18n.t('Login.error.siretNumberPattern')
                            }
                        })
                    }}
                    id="siretNumber"
                    label={i18n.t('Login.placeholder.siretNumber')}
                    error={!!errors.siret}
                    type="number"
                />
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('firstname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{3,}$/,
                                message: i18n.t('Login.error.firstNamePattern')
                            }
                        })
                    }}
                    id="firstName"
                    label={i18n.t('Login.placeholder.firstName') + '*'}
                    error={!!errors.firstname}
                    type="text"
                />
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('lastname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{3,}$/,
                                message: i18n.t('Login.error.lastNamePattern')
                            }
                        })
                    }}
                    id="lastName"
                    label={i18n.t('Login.placeholder.lastName') + '*'}
                    error={!!errors.lastname}
                    type="text"
                />
                <SignEvalTextField
                    icon={<MailOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        })
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email') + '*'}
                    error={!!errors.email}
                    type="email"
                />
                <ChakraProvider>
                    <ChakraPhone value={phoneValue} onChange={setPhoneValue} />
                </ChakraProvider>
                {errors.label && <Typography sx={{ color: '#ff0000' }}>{i18n.t('Login.error.trainingCenterPattern')}</Typography>}
                {errors.siret && !errors.label && <Typography sx={{ color: '#ff0000' }}>{i18n.t('Login.error.siretNumberPattern')}</Typography>}
                {errors.firstname && !errors.label && !errors.siret && <Typography sx={{ color: '#ff0000' }}>{i18n.t('Login.error.firstNamePattern')}</Typography>}
                {errors.lastname && !errors.label && !errors.siret && !errors.firstname && <Typography sx={{ color: '#ff0000' }}>{i18n.t('Login.error.lastNamePattern')}</Typography>}
                {errors.email && !errors.label && !errors.siret && !errors.firstname && !errors.lastname && <Typography sx={{ color: '#ff0000' }}>{i18n.t('Login.error.emailPattern')}</Typography>}
                {phoneError && <Typography sx={{ color: '#ff0000' }}>{phoneError}</Typography>}
                <Typography sx={{ display: 'flex', width: '100%', maxWidth: '35em', height: 'auto', fontSize: '0.7em', color: theme.palette.text.primary }}>
                    {'*' + i18n.t('Login.RequiredFields')}
                </Typography>
            </FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '70%', height: '18%', maxHeight: '8em' }}>
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('Login.link.next')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
                    <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</Typography>
                    <Link id="signUpButton" to="/">
                        <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary, textDecoration: 'underline' }}>{i18n.t('Login.link.logIn')}</Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default SignUpInfo;
