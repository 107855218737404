import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import Logo from '../../components/Logo';

// MUI imports
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

// Helpers
import { useTheme } from '../../hooks/useTheme';
import { useRegistrationContext } from '../../hooks/useRegistrationContext';

type FormValues = {
    password: string;
    confirmPassword: string;
};

const SignUpPasswords = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { registrationData, setRegistrationData } = useRegistrationContext();

    const [isMatchPassword, setIsMatchPassword] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        if (isOpen) setIsOpen(false);
    };
    const minLength = 10;

    const dropBoxColor = theme.palette.mode === 'dark' ? '#212A32' : 'default-border-color';

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<FormValues>({ mode: 'onTouched', shouldUnregister: false });

    const password = watch('password') || '';

    const handleSignUp: SubmitHandler<FormValues> = async (data) => {
        const { password } = data;

        if (password !== data.confirmPassword) {
            setIsMatchPassword(false);
            return;
        }

        setRegistrationData({
            ...registrationData,
            password
        });

        navigate('/signup-loading');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Logo size="large" />
            <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%', fontSize: '0.95em', fontWeight: '500', color: theme.palette.text.primary }}>
                {i18n.t('Login.PasswordTitle')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '70%',
                    maxWidth: '25em',
                    height: !isOpen ? '20%' : '47%',
                    maxHeight: !isOpen ? '9em' : '22em'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.2em', width: '100%', justifyContent: 'center' }}>
                    <SignEvalTextField
                        onFocus={handleOpen}
                        icon={
                            <InputAdornment position="end" sx={{ color: theme.palette.text.primary }}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    sx={{ marginRight: '1px', color: theme.palette.text.primary }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        iconPosition="end"
                        registerProps={{
                            ...register('password', {
                                required: true,
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/,
                                    message: i18n.t('Login.error.password')
                                }
                            })
                        }}
                        label={i18n.t('Login.placeholder.password')}
                        error={!!errors.password}
                        type={showPassword ? 'text' : 'password'}
                    />
                    {isOpen && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                height: '9.5em',
                                color: theme.palette.text.primary,
                                borderRadius: '10px',
                                alignItems: 'center',
                                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                bgcolor: dropBoxColor
                            }}
                        >
                            <LinearProgress
                                variant="determinate"
                                value={Math.min((password.length * 100) / minLength, 100)}
                                sx={{
                                    width: '95%',
                                    height: '0.7em',
                                    marginTop: '0.5em',
                                    bgcolor: 'background.level3',
                                    color: theme.palette.text.custom,
                                    borderRadius: '50px'
                                }}
                            />
                            <Typography component="span" variant="body2" sx={{ fontSize: '0.8em', marginRight: '0.5em', marginTop: '0.1em' }}>
                                {password.length < 3 && i18n.t('Login.passwordStrength.0')}
                                {password.length >= 3 && password.length < 5 && i18n.t('Login.passwordStrength.1')}
                                {password.length >= 5 && password.length < 7 && i18n.t('Login.passwordStrength.2')}
                                {password.length >= 7 && i18n.t('Login.passwordStrength.3')}
                            </Typography>
                            <Divider sx={{ width: '100%', marginTop: '0.5em' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', padding: '0.5em' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                    {password.length >= 8 ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                    {i18n.t('Login.passwordRequirements.length')}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                    {password.match(/[A-Z]/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                    {i18n.t('Login.passwordRequirements.uppercase')}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                    {password.match(/\d/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                    {i18n.t('Login.passwordRequirements.number')}
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                    {password.match(/\W/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                    {i18n.t('Login.passwordRequirements.special')}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                <SignEvalTextField
                    onFocus={handleClose}
                    icon={
                        <InputAdornment position="end" sx={{ color: theme.palette.text.primary }}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                edge="end"
                                sx={{ marginRight: '1px', color: theme.palette.text.primary }}
                            >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            <LockOutlinedIcon />
                        </InputAdornment>
                    }
                    iconPosition="end"
                    registerProps={{
                        ...register('confirmPassword', {
                            required: true
                        })
                    }}
                    label={i18n.t('Login.placeholder.confirmPassword')}
                    error={!!errors.confirmPassword}
                    type={showConfirmPassword ? 'text' : 'password'}
                />

                {errors.password && <Typography sx={{ fontSize: '0.8em', color: 'red' }}>{i18n.t('Login.error.password')}</Typography>}
                {!isMatchPassword && <Typography sx={{ fontSize: '0.8em', color: 'red' }}>{i18n.t('Login.error.passwordMatch')}</Typography>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '70%', height: '18%', maxHeight: '8em' }}>
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(handleSignUp)}>
                    {i18n.t('Login.link.signUp')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
                    <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</Typography>
                    <Link id="signUpButton" to="/">
                        <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary, textDecoration: 'underline' }}>{i18n.t('Login.link.logIn')}</Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default SignUpPasswords;
