import { useTheme } from '../hooks/useTheme';

// Images
import logoSignEvalDefault from '../assets/images/logoSignEvalDefault.png';
import logoSignEvalWhite from '../assets/images/logoSignEvalWhite.png';

interface LogoProps {
    size: 'small' | 'large';
}

const Logo = ({ size }: LogoProps) => {
    const theme = useTheme();
    const logoSignEval = theme.palette.mode === 'light' ? logoSignEvalDefault : logoSignEvalWhite;

    const imageStyle = {
        width: size === 'small' ? '7em' : '9em',
        height: 'auto'
    };

    return <img id="logo" src={logoSignEval} alt="Logo Sign Eval" style={imageStyle} />;
};

export default Logo;
