import React from 'react';
import { useTheme } from '../hooks/useTheme';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Components
import Logo from './Logo';
import DrawerMenu from './DrawerMenu';

// Images
import logoSignEvalDefault from '../assets/images/logoSignEvalDefault.png';
import logoSignEvalWhite from '../assets/images/logoSignEvalWhite.png';

// MUI imports
import AppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';

const HeaderBar: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        navigate(-1);
    };

    const logoSignEval = theme.palette.mode === 'light' ? logoSignEvalDefault : logoSignEvalWhite;

    const shouldShowBackButton = location.pathname !== '/sessions';

    return (
        <Box sx={{ width: '100%', height: 'auto', marginLeft: 'auto', marginRight: 'auto', backgroundColor: theme.palette.background.default }}>
            <AppBar
                position="static"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '95%',
                    height: '75px',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    backgroundImage: 'none',
                    boxShadow: 'none'
                }}
            >
                <Box sx={{ display: 'flex', width: '24.5px', height: '24.5px' }}>
                    {shouldShowBackButton ? <ChevronLeftIcon onClick={handleBackClick} sx={{ color: theme.palette.text.primary }} /> : <Box id="buttonBack"></Box>}
                </Box>
                <Link to="/sessions">
                    <Logo size="small" />
                </Link>
                <DrawerMenu />
            </AppBar>
        </Box>
    );
};

export default HeaderBar;
