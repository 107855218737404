import React from 'react';
import { useTheme } from '../hooks/useTheme';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ContentInfoProps {
    title: string;
}

const ContentInfo: React.FC<ContentInfoProps> = ({ title }) => {
    const theme = useTheme();

    return (
        <Box sx={{ marginBottom: '0.35em', marginLeft: '1em' }}>
            <Typography sx={{ color: theme.palette.secondary.main, fontSize: '1.05em', fontWeight: '700' }}>{title}</Typography>
        </Box>
    );
};

export default ContentInfo;
