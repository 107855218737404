import { useState, useEffect } from 'react';
import i18n from '../../i18n/i18n';
import { useTheme } from '../../hooks/useTheme';
import { useRegistrationContext } from '../../hooks/useRegistrationContext';
import { signUp } from '../../helpers/api/callAuthentification';
import Logo from '../../components/Logo';
import SignEvalButton from '../../components/SignEvalButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import { blue, green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface MessageBoxProps {
    message: string;
    buttonText?: string;
    buttonAction?: () => void;
}

const SignUpLoading = () => {
    const theme = useTheme();
    const { registrationData } = useRegistrationContext();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const bgLoaderColor = theme.palette.mode === 'dark' ? '#15202B' : '#FFFFFF';
    const buttonSx = {
        bgcolor: isSuccess ? green[500] : isFailed ? red[500] : bgLoaderColor,
        color: 'white',
        boxShadow: 'none'
    };

    const MessageBox = ({ message, buttonText, buttonAction }: MessageBoxProps) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                maxWidth: '30em',
                fontSize: '0.8em',
                textAlign: 'center',
                color: 'text.primary'
            }}
        >
            <Typography sx={{ fontSize: '1.1em' }}>{message}</Typography>
            {buttonText && buttonAction && (
                <SignEvalButton sx={{ position: 'absolute', bottom: '1.75em', width: '70%' }} size="large" backgroundColor="blue" onClick={buttonAction}>
                    {buttonText}
                </SignEvalButton>
            )}
        </Box>
    );

    useEffect(() => {
        const requestSignUp = async () => {
            try {
                const response = await signUp(registrationData);
                setIsSuccess(!!response);
                setIsFailed(!response);
            } catch {
                setIsFailed(true);
            } finally {
                setIsLoading(false);
            }
        };

        requestSignUp();
    }, [registrationData]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Logo size="large" />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    height: '20%',
                    minHeight: '10em',
                    maxHeight: '11em',
                    marginTop: '-1em'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab sx={buttonSx}>{isSuccess ? <CheckIcon /> : isFailed ? <CloseIcon /> : null}</Fab>
                        {isLoading && <CircularProgress size={68} sx={{ color: blue[400], position: 'absolute', top: -6, left: -6, zIndex: 1 }} />}
                    </Box>
                </Box>
                {isSuccess && <MessageBox message={i18n.t('Login.loader.successMessage')} buttonText={i18n.t('Login.link.logIn')} buttonAction={() => window.location.replace('/')} />}
                {isFailed && <MessageBox message={i18n.t('Login.loader.failedMessage')} buttonText={i18n.t('Login.link.retry')} buttonAction={() => window.location.replace('/signup-info')} />}
                {!isSuccess && !isFailed && <MessageBox message={`${i18n.t('Login.loader.loadingMessage')}\n${i18n.t('Login.loader.pleaseWait')}`} />}
            </Box>
        </Box>
    );
};

export default SignUpLoading;
